import { instance, getAxiosConfig } from "../axios-config";
import { mapToAssetEvent, mapToAsset, mapToActiveWindows } from "./util";
import moment from "moment";
// import { STREAM_TYPE } from "constants/assetPositions.ts";
import {
  StreamInfo,
  Asset,
  DozerVideoSegment,
  DozerVideoSegmentByPosition,
  AllCamerasVideoSegmentUrls,
  AssetEvent,
  TimeWindow,
  CameraPosition,
} from "../models/models";

export const getAssetOverview = async (assetId: Asset["assetId"]) => {
  const idToken = sessionStorage.getItem("token");

  if (!assetId || !idToken) {
    throw Error("Token required.");
  }

  const fromToday = moment().startOf("day");
  const today = moment().endOf("day").valueOf();
  const fromWeek = fromToday.subtract(7, "days").valueOf();
  const fromMonth = fromToday.subtract(30, "days").valueOf();

  const responses = await Promise.all([
    instance.get(
      `/assets/${assetId}/events/summary`,
      getAxiosConfig({ idToken: idToken }, null, { from: fromToday.valueOf(), to: today })
    ),
    instance.get(
      `/assets/${assetId}/events/summary`,
      getAxiosConfig({ idToken: idToken }, null, { from: fromWeek, to: today })
    ),
    instance.get(
      `/assets/${assetId}/events/summary`,
      getAxiosConfig({ idToken: idToken }, null, {
        from: fromMonth,
        to: today.valueOf(),
      })
    ),
    instance.get(
      `/assets/${assetId}/events/recent`,
      getAxiosConfig({ idToken: idToken }, null, {
        olderThan: moment().valueOf(),
        count: 50,
      })
    ),
  ]);

  const [dayEventSummaries, weekEventSummaries, monthEventSummaries, events] = responses;

  const mappedEvents = events.data.events
    .map((e: AssetEvent) => mapToAssetEvent(e, {}))
    .sort((a: AssetEvent, b: AssetEvent) => b.eventTimestamp - a.eventTimestamp);

  return {
    riskLevels: {
      /* TODO */ day: [],
      month: [],
      week: [],
    },
    recentEvents: mappedEvents,
    eventsSummaries: {
      day: {
        incidents: dayEventSummaries.data.numIncidents,
        warnings: dayEventSummaries.data.numWarnings,
      },
      week: {
        incidents: weekEventSummaries.data.numIncidents,
        warnings: weekEventSummaries.data.numWarnings,
      },
      month: {
        incidents: monthEventSummaries.data.numIncidents,
        warnings: monthEventSummaries.data.numWarnings,
      },
    },
  };
};

// TODO: Pagination for endless scroll
export const getAssetEvents = async (assetId: Asset["assetId"], from: number, to: number) => {
  const idToken = sessionStorage.getItem("token");

  if (!assetId || !idToken) {
    throw Error("Token required.");
  }

  try {
    const events = await instance.get(
      `/assets/${assetId}/events`,
      getAxiosConfig({ idToken }, null, {
        from,
        to,
      })
    );

    const mappedEvents = events.data.events
      .map((e: AssetEvent) => mapToAssetEvent(e, {}))
      .sort((a: AssetEvent, b: AssetEvent) => b.eventTimestamp - a.eventTimestamp);

    return mappedEvents;
  } catch (error: any) {
    console.log("error", error);
    return [];
  }
};

type AssetVideoHistoryResponse = {
  segmentsByPosition: DozerVideoSegmentByPosition;
  segmentEvents: AssetEvent[];
};

/**
 *
 * @param {string} assetId - the id of the asset
 * @param {object[]} streamArnsByPosition - stream arns and their positions for all cameras on the asset. Format { position: string, streamArn: string}
 * @param {number} from - start date of history in numerical format
 * @param {number} to - end date of history in numerical format
 * @returns {DozerVideoSegmentsByPosition[]} [{ position: string, result: [] }]
 */
export const getAssetVideoHistory = async (
  assetId: Asset["assetId"],
  streamArnsByPosition: StreamInfo[],
  from: number,
  to: number
): Promise<AssetVideoHistoryResponse> => {
  const idToken = sessionStorage.getItem("token");

  if (!assetId || !idToken) {
    console.log("Token required.");
  }

  const segmentsByPosition: DozerVideoSegmentByPosition = {};
  let segmentEvents: AssetEvent[] = [];

  for (let i = 0; i < streamArnsByPosition.length; i++) {
    const { kinesisStreamArn: streamArn, position } = streamArnsByPosition[i];

    let result = null;

    try {
      result = await instance.get(
        `/assets/${assetId}/video`,
        getAxiosConfig({ idToken: idToken }, null, {
          from,
          to,
          streamArn,
        })
      );
    } catch (error) {
      console.log(`Failed to get asset video history for streamArn ${streamArn}`, error);
    }

    if (result) {
      const segments: DozerVideoSegment[] = result.data?.map((r: DozerVideoSegment) => {
        const segmentEventsForSegment = r.segmentEvents.map((e) =>
          mapToAssetEvent(e, {}, position)
        );
        segmentEvents = [...segmentEvents, ...segmentEventsForSegment];

        return {
          start: r.start,
          end: r.end,
          startFragment: r.startFragment,
          endFragment: r.endFragment,
          numberOfFragments: r.numberOfFragments,
          segmentEvents: segmentEventsForSegment,
        };
      });

      segmentsByPosition[position] = segments;
    }
  }

  // all cameras segment events within the day
  segmentEvents.sort((a, b) => b.eventTimestamp - a.eventTimestamp);

  return { segmentsByPosition, segmentEvents };
};

export const getAssetVideoFeed = async (
  assetId: string,
  streamArn: StreamInfo,
  startingFrom: number,
  ending: number,
  position: CameraPosition | string
  // isChromium: boolean
): Promise<AllCamerasVideoSegmentUrls> => {
  const idToken = sessionStorage.getItem("token");

  if (!assetId || !idToken) {
    console.log("Token required.");
  }

  const expiresIn = 43200; // 12 hours

  const apiUrl = `/assets/${assetId}/video/archive/hls`;

  // if (isChromium) {
  //   apiUrl = `/assets/${assetId}/video/archive/dash`;
  // }

  const { kinesisStreamArn } = streamArn;

  let finalResult: AllCamerasVideoSegmentUrls = {};
  try {
    const result = await instance.get(
      apiUrl,
      getAxiosConfig({ idToken: idToken }, null, {
        from: startingFrom,
        to: ending,
        streamArn: kinesisStreamArn,
        expiresIn,
      })
    );

    finalResult[position] = {
      [startingFrom]: result.data,
    };
  } catch (error) {
    console.log("Error getting segment urls", error);
  }

  return finalResult;
};

type EventInfo = {
  numWarnings: number;
  numIncidents: number;
  numNearMisses: number;
  activeWindows: TimeWindow[];
};

type AssetsResponse = {
  asset: Asset;
  eventInfo: EventInfo;
};

export const getAssets = async (orgId: string) => {
  const idToken = sessionStorage.getItem("token");

  if (!orgId || !idToken) {
    throw Error("Org id and token required.");
  }

  const assets = await instance.get(
    `/org/${orgId}/assets/cards`,
    getAxiosConfig({ idToken: idToken })
  );

  return {
    assets: assets.data.map((a: AssetsResponse) => {
      return {
        asset: mapToAsset(a.asset),
        numWarnings: a.eventInfo.numWarnings,
        numIncidents: a.eventInfo.numIncidents,
        numNearMisses: a.eventInfo.numNearMisses,
        activeWindows: a.eventInfo.activeWindows.map(mapToActiveWindows),
      };
    }),
  };
};

type StreamInfoResponse = {
  dozerProcessingStatus?: string;
  kinesisStreamArn?: string;
  kinesisStreamName?: string;
  kinesisStreamStatus?: string;
  streamId?: string;
  streamType?: string;
};

type AssetStream = {
  [position: string]: StreamInfoResponse;
};

export const getAsset = async (assetId: string) => {
  const idToken = sessionStorage.getItem("token");

  if (!idToken) {
    console.log("Token required.");
  }

  if (!assetId) {
    console.log("Asset id required");
  }

  let assetResponse = null;

  try {
    assetResponse = await instance.get(
      `/test/assets/${assetId}`,
      getAxiosConfig({ idToken: idToken })
    );
  } catch (error) {
    console.log("error", error);
  }

  let asset: Asset | null = null;

  if (assetResponse?.data) {
    asset = mapToAsset(assetResponse.data.asset);
    const streams = assetResponse?.data.assetStreams;
    const videoStreamInfo: StreamInfo[] = [];

    Object.entries(streams).forEach(([assetPosition, _v]) => {
      const position = Object.entries(CameraPosition).find(([k, _v]) => k === assetPosition)?.[1];

      const streamInfo = streams[assetPosition];

      if (position) {
        videoStreamInfo.push({
          position,
          streamStatus: streamInfo.kinesisStreamStatus,
          kinesisStreamArn: streamInfo.kinesisStreamArn,
          streamId: streamInfo.streamId,
        });
      }
    });

    asset.videoStreamInfo = videoStreamInfo;
  }

  return asset;
};

export const getMonthlyVideoInfo = async (
  assetId: string,
  streamArnsByPosition: StreamInfo[],
  from: number,
  to: number,
  timezone: string
) => {
  const idToken = sessionStorage.getItem("token");

  if (!idToken) {
    console.log("Token required.");
  }

  if (!assetId) {
    console.log("Asset id required");
  }

  for (let i = 0; i < streamArnsByPosition.length; i++) {
    const { kinesisStreamArn: streamArn } = streamArnsByPosition[i];

    let result = null;

    try {
      result = await instance.get(
        `/assets/${assetId}/video/monthly`,
        getAxiosConfig({ idToken: idToken }, null, {
          from,
          to,
          streamArn,
          timeframeTimeZone: timezone,
        })
      );
    } catch (error) {
      console.log("error", error);
    }

    return result?.data?.map((res: any) => ({
      dayTimestamp: res.dayTimestamp,
      eventLevels: res.eventLevels,
    }));
  }
};

export const getAssetLiveVideoFeed = async (
  assetId: string,
  streamArn: string,
  startingFrom: number
) => {
  const expiresIn = 43200; // 12 hours

  const idToken = sessionStorage.getItem("token");

  if (!idToken) {
    console.log("Token required.");
  }

  if (!assetId) {
    console.log("Asset id required");
  }

  let result = null;

  try {
    result = await instance.get(
      `/assets/${assetId}/video/live`,
      getAxiosConfig({ idToken: idToken }, null, {
        from: startingFrom, // should be start of day, in UTC?
        // to,
        streamArn,
        expiresIn,
      })
    );
  } catch (error) {
    console.log("error", error);
  }

  return result?.data;
};
